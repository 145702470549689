import { PropsWithChildren, ReactNode } from "react";
import {
	ActionIcon,
	Box,
	BoxProps,
	Group,
	MantineColor,
	Paper,
	PaperProps,
	Title,
	Tooltip,
	useMantineTheme
} from "@mantine/core";
import { TbInfoCircle } from "react-icons/tb";
import { useTheme } from "../../context/theme.context";

export interface IReportCardProps extends BoxProps {
	title: ReactNode;
	color: MantineColor;
	titleColor?: MantineColor;
	minWidth?: string | number;
	containerProps?: PaperProps;
	tooltip?: string;
}

export function ReportCard(props: PropsWithChildren<IReportCardProps>) {
	const theme = useMantineTheme();
	const {
		containerProps,
		title,
		color,
		titleColor,
		minWidth,
		tooltip,
		...boxProps
	} = props;
	const mainColor = color.split(".")[0];

	return (
		<Paper
			{...containerProps}
			withBorder
			radius={containerProps?.radius ?? "lg"}
			sx={{overflow: "hidden", ...containerProps?.sx}}
			miw={minWidth ?? 200}
		>
			<Paper
				px={"md"}
				py={"xs"}
				sx={(theme) => ({
					backgroundColor: theme.fn.themeColor(color, undefined, undefined, true)
				})}
				radius={0}
			>
				<Group position={"apart"}>
					<Title
						size={"h3"}
						color={theme.fn.themeColor(titleColor ?? `${mainColor}.0`, undefined, undefined, true)}
					>
						{title}
					</Title>
					{
						!!tooltip &&
						<Tooltip
							label={tooltip}
							disabled={!tooltip}
							multiline
							maw={300}
							openDelay={250}
							events={{hover: true, focus: true, touch: true}}
						>
							<ActionIcon
								variant={"transparent"}
								color={titleColor ?? `${mainColor}.2`}
							>
								<TbInfoCircle
									size={18}
								/>
							</ActionIcon>
						</Tooltip>
					}
				</Group>
			</Paper>
			<Box {...boxProps}>
				{props.children}
			</Box>
		</Paper>
	);
}

export function ReportCardText({text}: { text: ReactNode }) {
	const theme = useMantineTheme();
	const {isDark} = useTheme();
	return (
		<Title
			size={"xxx-large"}
			p={"md"}
			align={"center"}
			color={isDark ? theme.colors.dark[0] : theme.colors.dark[4]}
		>
			{text}
		</Title>
	);
}
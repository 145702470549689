import { Box, Skeleton, useMantineTheme } from "@mantine/core";
import { useTheme } from "../../../context/theme.context";
import dynamic from "next/dynamic";
import { ILineChart } from "../../../models/reporting.model";
import { useColorStrings } from "../../../hooks/helpers.hooks";

const ResponsiveLine = dynamic(() => import("@nivo/line")
	.then((mod) =>
		mod.ResponsiveLine
	), {ssr: false}
);

export interface ILineChartProps {
	data: ILineChart[];
	loading: boolean;
	height?: number | string;
}

export function LineChart(props: ILineChartProps) {
	const theme = useMantineTheme();
	const {isDark} = useTheme();
	const defaultColors = useColorStrings();

	function GetMax(): number {
		if (props.data.length === 0) return 10;
		const responsesData = props.data.find(i => i.id === "Responses")?.data ?? [];
		const responseMax = Math.max(...responsesData.map(i => i.y));
		return responseMax * 1.1;
	}

	return (
		<Skeleton visible={props.loading}>
			<Box
				pos={"relative"}
				h={props.height ?? 200}
				sx={{flex: 1}}
			>
				{
					!props.loading &&
					<Box
						pos={"absolute"}
						w={"100%"}
						h={"100%"}
					>
						<ResponsiveLine
							animate
							colors={[defaultColors[10]]}
							data={props.data}
							margin={{top: 12, right: 24, bottom: 24, left: 60}}
							xFormat={"time:%b %d"}
							xScale={{
								format: '%Y-%m-%d',
								precision: 'day',
								type: 'time'
							}}
							yScale={{
								type: 'linear',
								max: GetMax()
							}}
							yFormat={">-.0r"}
							curve={"monotoneX"}
							axisBottom={{
								format: '%b %d',
								tickValues: 5
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'Count',
								legendOffset: -40,
								legendPosition: 'middle',
								tickValues: 5
							}}
							useMesh
							theme={{
								textColor: theme.colors.gray[6],
								grid: {line: {stroke: isDark ? theme.colors.gray[7] : theme.colors.gray[3]}},
								axis: {ticks: {line: {stroke: isDark ? theme.colors.gray[7] : theme.colors.gray[3]}}},
								crosshair: {line: {stroke: isDark ? theme.colors.gray[3] : theme.colors.gray[7]}},
								tooltip: {container: {background: isDark ? theme.colors.gray[7] : "#fff"}}
							}}
						/>
					</Box>
				}
			</Box>
		</Skeleton>
	);
}
import { Box, Center, createStyles, Skeleton, Stack, Text, useMantineTheme } from "@mantine/core";
import { useTheme } from "../../../context/theme.context";
import dynamic from "next/dynamic";
import { IPieChartData } from "../../../models/reporting.model";
import { useColorStrings } from "../../../hooks/helpers.hooks";
import { CheckInTypeLabels } from "../../../models/checkInResponse.model";

const ResponsivePie = dynamic(() => import("@nivo/pie")
	.then((mod) =>
		mod.ResponsivePie
	), {ssr: false}
);

export interface IPieChartProps {
	data: IPieChartData[];
	loading: boolean;
	height?: number | string;
	title?: string;
}

const useSvgStyles = createStyles(() => ({
	svg: {
		["& svg"]: {
			overflow: "visible !important"
		}
	}
}));

export function PieChart(props: IPieChartProps) {
	const theme = useMantineTheme();
	const {isDark} = useTheme();
	const defaultColors = useColorStrings();
	const {classes} = useSvgStyles();

	const hasData = props.data.some(i => i.value > 0);

	function getColor(label: string) {
		switch (label) {
			case CheckInTypeLabels.Work:
				return defaultColors[0];
			case CheckInTypeLabels.PhysicalHealth:
				return defaultColors[1];
			case CheckInTypeLabels.MentalHealth:
				return defaultColors[2];
			case CheckInTypeLabels.Money:
				return defaultColors[3];
			case CheckInTypeLabels.Relationships:
				return defaultColors[4];
			default:
				return theme.colors.gray[6];
		}
	}

	return (
		<Skeleton
			visible={props.loading}
			sx={{flex: 1}}
		>
			<Stack
				spacing={0}
				h={props.height ?? 300}
				sx={{flex: 1}}
			>
				{
					!hasData &&
					<Center
						w={"100%"}
						h={"100%"}
					>
						<Text color={"dimmed"} size={"lg"}>No Data</Text>
					</Center>
				}
				<Box
					pos={"relative"}
					sx={{flex: 1}}
					miw={props.height ?? 300}
				>
					<Box
						pos={"absolute"}
						w={"100%"}
						h={"100%"}
						className={classes.svg}
					>
						<ResponsivePie
							colors={(datum) => getColor(datum.id.toString())}
							data={props.data}
							margin={{right: 72, left: 72}}
							enableArcLabels={false}
							arcLinkLabelsTextColor={{from: 'color'}}
							arcLinkLabelsColor={{from: 'color'}}
							arcLinkLabelsDiagonalLength={8}
							arcLinkLabelsStraightLength={8}
							arcLinkLabelsSkipAngle={10}
							activeOuterRadiusOffset={8}
							theme={{
								textColor: theme.colors.gray[2],
								grid: {line: {stroke: isDark ? theme.colors.gray[7] : theme.colors.gray[3]}},
								axis: {ticks: {line: {stroke: isDark ? theme.colors.gray[7] : theme.colors.gray[3]}}},
								labels: {text: {fill: isDark ? theme.colors.gray[7] : theme.colors.gray[3]}},
								tooltip: {container: {background: isDark ? theme.colors.gray[7] : "#fff"}}
							}}
						/>
					</Box>
				</Box>
				{
					props.title &&
					<Text align={"center"} weight={"bold"}>{props.title}</Text>
				}
			</Stack>
		</Skeleton>
	);
}
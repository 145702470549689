import AppWrapper from "../components/appWrapper/appWrapper";
import Seo from "../components/seo/seo";
import { Button, Group, Select, Skeleton, Stack, Table, Title, useMantineTheme } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { IPieChartData, IReportData } from "../models/reporting.model";
import ApiError from "../interfaces/apiError";
import { useApi } from "../context/api.context";
import { DatePickerInput } from "@mantine/dates";
import { useMemo, useState } from "react";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { ErrorBoundary } from "../components/errorBoundary/errorFallback";
import { ReportCard, ReportCardText } from "../components/reportCard/reportCard";
import { LineChart } from "../components/charts/line/lineChart";
import { PieChart } from "../components/charts/pie/pieChart";
import { ITeam } from "../models/team.model";
import { useTheme } from "../context/theme.context";

export function ReportsContent() {
	const api = useApi();
	const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 90)));
	const [endDate, setEndDate] = useState(startOfDay(new Date()));
	const [team, setTeam] = useState<ITeam | null>(null);

	const reportQuery = useQuery<IReportData, ApiError>(
		["reporting.GetReport", startDate, endDate, api.company, team],
		() => api.reporting.GetReport(startDate, endDate, team),
		{
			enabled: !!api.company,
		},
	);

	const teamsQuery = useQuery<ITeam[], ApiError>(
		["teams.Get", api.company],
		() => api.teams.Get(),
		{
			enabled: !!api.company,
		},
	);

	function getValue(value?: number) {
		return value ?? 0;
	}

	function getPercent(value?: number) {
		return `${value?.toFixed(0) ?? 0}%`;
	}

	function clear() {
		setStartDate(startOfDay(subDays(new Date(), 90)));
		setEndDate(startOfDay(new Date()));
		setTeam(null);
	}

	return (
		<Group align={"start"}>
			<Stack sx={{ flex: 4 }}>
				<ReportCard
					title={
						<Group
							mt={-8}
							align={"flex-end"}
						>
							<DatePickerInput
								label={"Start Date"}
								value={startDate}
								onChange={(value) => setStartDate(startOfDay(value!))}
								maxDate={endDate}
								sx={(theme) => ({
									label: {
										color: theme.colors.gray[0],
										paddingBottom: 4,
									},
								})}
							/>
							<DatePickerInput
								label={"End Date"}
								value={endDate}
								onChange={(value) => setEndDate(endOfDay(value!))}
								minDate={startDate}
								sx={(theme) => ({
									label: {
										color: theme.colors.gray[0],
										paddingBottom: 4,
									},
								})}
							/>
							<Select
								clearable
								label={"Team"}
								placeholder={"Select team..."}
								data={teamsQuery.data?.map(i => ({
									label: i.name,
									value: i.id!.toString(),
								})) ?? []}
								value={team?.id?.toString() ?? null}
								onChange={(value) => {
									const item = teamsQuery.data?.find(i => i.id === (value ? +value : null));
									setTeam(item ?? null);
								}}
								sx={(theme) => ({
									label: {
										color: theme.colors.gray[0],
										paddingBottom: 4,
									},
								})}
							/>
							<Button
								variant={"light"}
								onClick={clear}
							>
								Clear
							</Button>
						</Group>
					}
					color={"gray"}
					p={"lg"}
				>
					<Stack>
						<Group>
							<ReportCard
								title={"Sent"}
								color={"teal"}
								containerProps={{ sx: { flex: 1 } }}
								tooltip={"Total surveys sent"}
							>
								<ReportCardText text={getValue(reportQuery.data?.surveysSentTotal)}/>
							</ReportCard>
							<ReportCard
								title={"Responses"}
								color={"violet"}
								containerProps={{ sx: { flex: 1 } }}
								tooltip={"Total responses received"}
							>
								<ReportCardText text={getValue(reportQuery.data?.surveysRespondedTotal)}/>
							</ReportCard>
							<ReportCard
								title={"Support"}
								color={"blue"}
								containerProps={{ sx: { flex: 1 } }}
								tooltip={"Total responses that included a request for support"}
							>
								<ReportCardText text={getValue(reportQuery.data?.supportRequestsTotal)}/>
							</ReportCard>
						</Group>
						<Group>
							<ReportCard
								title={"Engagement Rate"}
								color={"cyan"}
								containerProps={{ sx: { flex: 1 } }}
								tooltip={"Percentage of responses received out of the amount sent"}
							>
								<ReportCardText text={getPercent(reportQuery.data?.engagementRate)}/>
							</ReportCard>
							<ReportCard
								title={"Support Rate"}
								color={"yellow"}
								containerProps={{ sx: { flex: 1 } }}
								tooltip={"Percentage of requests for support out of the amount sent"}
							>
								<ReportCardText text={getPercent(reportQuery.data?.supportRate)}/>
							</ReportCard>
						</Group>
						<ReportCard
							title={"Support Categories"}
							color={"grape"}
							p={"lg"}
							containerProps={{ sx: { flex: 1 } }}
							tooltip={"Breakdown of support requests and referrals by support category"}
						>
							<Group align={"start"}>
								<Stack sx={{ flex: "1 !important" }}>
									<PieChart
										title={"Support Requests"}
										data={reportQuery.data?.supportCategoriesChart ?? []}
										loading={reportQuery.isLoading || reportQuery.isFetching}
									/>
									<CategoryTable
										loading={reportQuery.isLoading || reportQuery.isFetching}
										items={reportQuery.data?.supportCategoriesChart ?? []}
									/>
								</Stack>
								<Stack sx={{ flex: "1 !important" }}>
									<PieChart
										title={"Referrals"}
										data={reportQuery.data?.referralCategoriesChart ?? []}
										loading={reportQuery.isLoading || reportQuery.isFetching}
									/>
									<CategoryTable
										loading={reportQuery.isLoading || reportQuery.isFetching}
										items={reportQuery.data?.referralCategoriesChart ?? []}
									/>
								</Stack>
							</Group>
						</ReportCard>
						<ReportCard
							title={"Response Timeline"}
							color={"indigo"}
							p={"lg"}
							containerProps={{ sx: { flex: 1 } }}
							tooltip={"Timeline of surveys sent and responses over the selected period"}
						>
							<Group>
								<LineChart
									data={reportQuery.data?.responsesChart ?? []}
									loading={reportQuery.isLoading || reportQuery.isFetching}
								/>
							</Group>
						</ReportCard>
					</Stack>
				</ReportCard>
			</Stack>
			<Stack sx={{ flex: 1 }}>
				<ReportCard
					title={"Outstanding"}
					color={"red"}
					tooltip={"Current outstanding support requests"}
					minWidth={250}
				>
					<Group spacing={0}>
						<ReportCard
							title={"Total"}
							color={"gray"}
							containerProps={{
								radius: 0,
								sx: { flex: 1 },
							}}
						>
							<ReportCardText text={getValue(reportQuery.data?.outstandingSupportRequestsTotal)}/>
						</ReportCard>
						<ReportCard
							title={"14 Days"}
							color={"gray"}
							containerProps={{
								radius: 0,
								sx: { flex: 1 },
							}}
						>
							<ReportCardText text={getValue(reportQuery.data?.outstandingSupportRequestsMedium)}/>
						</ReportCard>
						<ReportCard
							title={"30 Days"}
							color={"gray"}
							containerProps={{
								radius: 0,
								sx: { flex: 1 },
							}}
						>
							<ReportCardText text={getValue(reportQuery.data?.outstandingSupportRequestsHigh)}/>
						</ReportCard>
					</Group>
				</ReportCard>
			</Stack>
		</Group>
	);
}

interface ICategoryTableProps {
	items: IPieChartData[];
	loading: boolean;
}

function CategoryTable(props: ICategoryTableProps) {
	const theme = useMantineTheme();
	const { isDark } = useTheme();
	const sortedItems = useMemo(() => {
		return props.items.sort((a, b) => b.value - a.value);
	}, [props.items]);

	const total = sortedItems.reduce((prev, cur) => (prev + cur.value), 0);

	return (
		<Skeleton visible={props.loading}>
			<Stack h={300}>
				<Table>
					<thead style={{ backgroundColor: isDark ? theme.colors.gray[9] : theme.colors.gray[1] }}>
						<tr>
							<th style={{ fontWeight: 500 }}>Category</th>
							<th style={{ textAlign: "right" }}>%</th>
						</tr>
					</thead>
					<tbody>
						{
							sortedItems.map(i =>
								<tr key={i.id}>
									<td>{i.id}</td>
									<td align={"right"}>{total === 0 ? "-" : `${(i.value / total * 100).toFixed(0)}%`}</td>
								</tr>,
							)
						}
					</tbody>
				</Table>
			</Stack>
		</Skeleton>
	);
}

export default function Reports() {
	return (
		<AppWrapper>
			<Seo
				title={"Reports"}
				description={`Reports for a company`}
			/>
			<Stack>
				<Title>Reports</Title>
				<ErrorBoundary>
					<ReportsContent/>
				</ErrorBoundary>
			</Stack>
		</AppWrapper>
	);
}